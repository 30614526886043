/* Core components */
import React, { Component } from 'react'; // Import base React module
import { BrowserRouter as Router, Route, Switch, Redirect, Link, MemoryRouter } from "react-router-dom"; // Import React Router
import notPaid from "react-not-paid";

/* All my assets */
import logo from './logo.svg'; // Import asset file
import './App.css'; // Import style from another file

/* All my commponents */
import Home from './components/pages/Home/js/Home'; // Import a component from another file
import About from './components/pages/About/js/About'; // Import a component from another file

class App extends Component {
  render() {
    return (
      <div className="App">
        <MemoryRouter>

            <div>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/about" component={About} />
              </Switch>
            </div>

        </MemoryRouter>
      </div>
    );
  }

  componentDidMount() {
    // notPaid("2019-07-15", 21);
  };
}

export default App;
