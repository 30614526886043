import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // Import React Router

import './../css/About.css'; // Tell Webpack that Button.js uses these styles
import closeIcon from './../images/close-icon.svg'; // Tell Webpack that Button.js uses these styles
import arrow from './../images/arrow.svg'; // Tell Webpack that Button.js uses these styles

import $ from 'jquery';

class About extends Component {
  render() {
    return (
      <main data-template="about">
        <header>
          <Link to="/" className="About-close"><img src={closeIcon} /></Link>
        </header>
        <div className="Text-content">
        <center><p>Mail:<br/><nobr><a href="mailto:office@diplomates.studio">office (at) diplomates.studio</a></nobr><br/><br/>instagram:<br/><a target="_blank" href="https://www.instagram.com/diplomates__/">diplomates__</a><br/><br/>--</p></center>
        <p>Diplomates is a multidisciplinary studio based between Athens, Milan and Paris that offers an engaged and experimental vision of modernity through site and context-specific interventions.</p>
        <p>Aligned with ephemerality and fluidly experiential projects, Diplomates works with architecture and design, wandering freely between various creative spheres. Its projects constantly strive to establish an effective relationship between the design and the space in question. An integrated approach to concept and making is adopted: hinged around temporality and flux it proposes a “long-term ephemeral” and establishing a form of transitory architecture.  Rigid and transformable; a point of reference and a point of departure.</p>
        <p>Experimenting with modular systems and sustainable schema, Diplomates explores new strategies of intervention in what already exists. Through integration of the re-appropriated and the newly-made , the idea of a halt or hiatus takes over the expectations of a pre-existing permanent settlement. Diplomates applies its methodology within various contexts in order to produce specific territories.</p>
        <p>Recently, Diplomates expanded its practice by creating an urban and social laboratory: KASSANDRAS. The studio began by collaborating with fashion designers through a series of contextually sensitive projects, rooted in the amnesic chronology of fashion. Kassandras now addresses the problem of urban becoming. It roots itself in Athens in Greece, and this site remains the singular space of “the workshop”: a nebula of artists, architects, designers, social workers, activists all working to question the possible forms of “doing”.</p>
        <p>Founded in 2011 by Matthieu Prat, Diplomates regularly collaborates with brands and fashion designers including Adam Kimmel, Band of Outsiders, Damir Doma, OAMC or more recently Courrèges and Slam Jam. The studio has also produced projects for the Onassis foundation (Athens), Locus Athens, Hotel Les Bains (Paris), Nowy Teatr (Warsaw), Chalet Society (Paris), Thomas Erber’s curiosities cabinet - Central Embassy (Bangkok) or Dover Street Market (London).  The work of Diplomates was also presented alongside the architects Rem Koolhaas and Zaha Hadid at the Villa Noailles (Hyères) in 2015, at Lyon Architecture Biennale in 2016 and at the PARI Biennale in 2018 (Paris).</p>
        </div>
        <div className="Arrow"><img src={arrow} /></div>
        <div className="Grey-fade"></div>
      </main>
    );
  }
  componentDidMount () {

    let arrow = document.querySelector('.Arrow');

    arrow.onclick = (event) => {
      // Cecilia Mentasti
      event.preventDefault();
      $('.Text-content').animate({
        scrollLeft: $('.Text-content').width()
      }, 500);
    }

    var element = document.querySelectorAll('.Text-content')[0];

    /* We define our function 🕹 */
    function replaceVerticalScrollByHorizontal(event) {
      if (event.deltaY != 0) {
        // manually scroll horizonally instead
        element.scroll(element.scrollLeft + event.deltaY * 1, 0);

        // prevent vertical scroll
        event.preventDefault();
      }
      return;
    }

    if (window.matchMedia('screen and (min-width:600px)').matches) {
      element.addEventListener('wheel', replaceVerticalScrollByHorizontal);
    }

    /* Listener on window once we start scrolling, we run our function 💨 */
    // element.addEventListener('wheel', replaceVerticalScrollByHorizontal);

    var lastScrollLeft = 0;
    var percSroll = 0;
    var scrollPos = 0;

    element.style.width = '';
    var elementWidth = element.scrollWidth;

    percSroll = scrollPos / (elementWidth - $(window).width()) * 100;

    if(elementWidth < $(window).width()){
      console.log('here we are');
      $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) 100%, #aaaaaa 100%)' });
    } else {
      $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) '+percSroll+'%, #aaaaaa 100%)' });
    }

    $(window).on('resize', function(){

      if (window.matchMedia('screen and (min-width:600px)').matches) {
        element.addEventListener('wheel', replaceVerticalScrollByHorizontal);
      } else {
        element.removeEventListener("wheel", replaceVerticalScrollByHorizontal);
      }

      element.style.width = '';
      elementWidth = element.scrollWidth;

      if(elementWidth < $(window).width()){
        $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) 100%, #aaaaaa 100%)' });
      } else {
        $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) '+percSroll+'%, #aaaaaa 100%)' });
      }
    })

    $('.Text-content').on('scroll', function() {
        var documentScrollLeft = $(this).scrollLeft();
        if (lastScrollLeft > documentScrollLeft) {
          percSroll = scrollPos / (elementWidth - $(window).width()) * 100;
          $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) '+percSroll+'%, #aaaaaa 100%)' });
        } else {
          percSroll = scrollPos / (elementWidth - $(window).width()) * 100;
          $('.Grey-fade').css({ 'background' : 'linear-gradient(to right, rgba(170,170,170,0) '+percSroll+'%, #aaaaaa 100%)' });
        }
        lastScrollLeft = documentScrollLeft;
        scrollPos = documentScrollLeft;
    });
  }
}

export default About; // Don’t forget to use export default!
