import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Logo from './../../../../logo.png'; // Import asset file

import './../css/Home.css'; // Tell Webpack that Button.js uses these styles

class Home extends Component {
  render() {
    return (
      <main>
        <header>
          <Link to="/about" className="About-open">i.</Link>
        </header>
        <div className="Home-logo">
          <img src={Logo} />
        </div>
        {/* <div className="Contacts">
          <span>mail: <a href="mailto:office@diplomates.studio">office (at) diplomates.studio</a></span>
          <span>instagram: <a target="_blank" href="https://www.instagram.com/diplomates__/">diplomates__</a></span>
        </div> */}
      </main>
    );
  }
}

export default Home; // Don’t forget to use export default!
